import React,{useEffect,useState} from "react";
import { printParams } from "../config";
const Statement = (bannerBottom) => {
    const [aboutSubHeading, setaboutSubHeading] = useState("");
    const [aboutSubPara, setAboutSubPara] = useState("");
    // useEffect(() => {
    //     // printParams("aboutContentSubHead", bannerStatement);
    //     const firstThemeData = bannerStatement;
    //     setaboutSubHeading(firstThemeData.bannerStatementShort)
    //     const strippedText = firstThemeData.bannerStatementLong.replace(/<p>/g, "").replace(/<\/p>/g, "");
    //     setAboutSubPara(strippedText)
    // }, [bannerStatement]);


    useEffect(() => {
        console.log("dddddddddddddddddd",bannerBottom)
        
        const bannerThemeData = bannerBottom.bannerBottom[0];
        // printParams("bannerData[0]", bannerThemeData[0]);
        // if(bannerBottom.length>0){
        if (bannerThemeData && bannerThemeData.themeData) {
          try {
              const bannerDatas = JSON.parse(bannerThemeData.themeData);
              printParams("bannerDatasbunch", bannerDatas);
              // setbannerDetails(bannerDatas)
              setaboutSubHeading(bannerDatas[0].equalityHeading)
              setAboutSubPara(bannerDatas[0].equalityContentParagraph)
              // setBannerSubHeading(bannerDatas[0].bannerSubHeading)
          } catch (error) {
              printParams("Error parsing bannerData:", error);
          }
        } else {
          printParams("bannerData or bannerData is undefined.");
        }
            }, [bannerBottom]);
    return (
        <div className="statement-wrapper">
            <div className="container">
                {/* <div className="col-lg-6 col-md-12 statement-left">
                    <p className="statement-para">{aboutSubHeading}</p>
                </div> */}
                <div className="col-lg-12 col-md-12 statement-right">
                    <p className="statement-para" dangerouslySetInnerHTML={{ __html: aboutSubPara }}/>
                    {/* <p className="statement-para">Union solidarity is based on the principle that union members are equal and deserve mutual respect at all levels. Any behaviour that creates conflict prevents us from working together to strengthen our union</p> */}
                </div>
            </div>
        </div>
    );
};

export default Statement;
