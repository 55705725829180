import React, { Suspense, useEffect, useState } from "react";
import "./style.css"
import { registerMail } from "../redux/reducers/loginSlice";
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
let Header = React.lazy(() => import("../" + localStorage.getItem("themeName") + "/header"));
let Footer = React.lazy(() => import("../" + localStorage.getItem("themeName") + "/footer"));


const Survey = ({ }) => {

    const dispatch = useDispatch();

    const [bargainingUnit, setBargainingUnit] = useState({
        question1: "Bargaining Unit",
        answer1: [
            { option: "OFFICE", is_checked: false },
            { option: "OUTSIDE", is_checked: false },
            { option: "PROFESSIONAL AND TECHNICAL", is_checked: false },
            { option: "TEMPORARY", is_checked: false }
        ]
    });

    const [bargainingItem, setBargainingItem] = useState({
        question2: "PLEASE RANK YOUR BARGAINING ITEMS (from 1 to 10 with 1 being the highest priority and 10 being the lowest priority)",
        answer2: [{
            id: 'bargainingItem1',
            label: 'WAGES/JOB EVALUATION',
            value: ''
        },
        {
            id: 'bargainingItem2',
            label: 'BENEFIT ENHANCEMENTS',
            value: ''
        },
        {
            id: 'bargainingItem3',
            label: 'JOB POSTING LANGUAGE IMPROVEMENTS',
            value: ''
        },
        {
            id: 'bargainingItem4',
            label: 'PREMIUM, ALLOWANCE AND ALLOTMENT INCREASES',
            value: ''
        },
        {
            id: 'bargainingItem5',
            label: 'APPROVED LEAVE IMPROVEMENTS (ie Bereavement, Parental/Maternity, Personal leave)',
            value: ''
        },
        {
            id: 'bargainingItem6',
            label: 'HOURS OF WORK AND SCHEDULING (Compressed, Flex, Remote work)',
            value: ''
        },
        {
            id: 'bargainingItem7',
            label: 'HEALTH AND SAFETY IMPROVEMENTS',
            value: ''
        },
        {
            id: 'bargainingItem8',
            label: 'SICK LEAVE',
            value: ''
        },
        {
            id: 'bargainingItem9',
            label: 'VACATION',
            value: ''
        },
        {
            id: 'bargainingItem10',
            label: 'SENIORITY/JOB SECURITY',
            value: ''
        }]
    });


    const [tableData, setTableData] = useState({
        question3: "PLEASE RATE THESE ITEMS IN TERMS OF IMPORTANCE TO IMPROVING TO YOU",
        answer3: [
            {
                question: "SHIFT PREMIUMS",
                options: [
                    { id: 'shiftPrmius1', label: 'IMPORTANT', value: false },
                    { id: 'shiftPrmius2', label: 'SOMEWHAT IMPORTANT', value: false },
                    { id: 'shiftPrmius3', label: 'NEUTRAL', value: false },
                    { id: 'shiftPrmius4', label: 'SOMEWHAT UNIMPORTANT', value: false },
                    { id: 'shiftPrmius5', label: 'UNIMPORTANT', value: false }
                ]
            },
            {
                question: "HEALTH CARE COVERAGE",
                options: [
                    { id: 'healthCare1', label: 'IMPORTANT', value: false },
                    { id: 'healthCare2', label: 'SOMEWHAT IMPORTANT', value: false },
                    { id: 'healthCare3', label: 'NEUTRAL', value: false },
                    { id: 'healthCare4', label: 'SOMEWHAT UNIMPORTANT', value: false },
                    { id: 'healthCare5', label: 'UNIMPORTANT', value: false }
                ]
            },
            {
                question: "POST RETIREMENT BENEFITS",
                options: [
                    { id: 'retirement1', label: 'IMPORTANT', value: false },
                    { id: 'retirement2', label: 'SOMEWHAT IMPORTANT', value: false },
                    { id: 'retirement3', label: 'NEUTRAL', value: false },
                    { id: 'retirement4', label: 'SOMEWHAT UNIMPORTANT', value: false },
                    { id: 'retirement5', label: 'UNIMPORTANT', value: false }
                ]
            },
            {
                question: "PARAMEDICAL BENEFITS (IE MASSAGE, CHIROPRACTIC, PHYSIO ETC)",
                options: [
                    { id: 'paramedical1', label: 'IMPORTANT', value: false },
                    { id: 'paramedical2', label: 'SOMEWHAT IMPORTANT', value: false },
                    { id: 'paramedical3', label: 'NEUTRAL', value: false },
                    { id: 'paramedical4', label: 'SOMEWHAT UNIMPORTANT', value: false },
                    { id: 'paramedical5', label: 'UNIMPORTANT', value: false }
                ]
            },
            {
                question: "RETENTION PAY",
                options: [
                    { id: 'retention1', label: 'IMPORTANT', value: false },
                    { id: 'retention2', label: 'SOMEWHAT IMPORTANT', value: false },
                    { id: 'retention3', label: 'NEUTRAL', value: false },
                    { id: 'retention4', label: 'SOMEWHAT UNIMPORTANT', value: false },
                    { id: 'retention5', label: 'UNIMPORTANT', value: false }
                ]
            }
        ]
    });

    const [comments, setComments] = useState({
        question4: "Any other comments",
        answer4: ''
    });


    const surveyData = [
        { ...bargainingUnit },
        { ...bargainingItem },
        { ...tableData },
        { ...comments }
    ];

    const handleCommentsChange = (event) => {
        setComments({
            ...comments,
            answer4: event.target.value,

        });
    };

    const handleRadioChange = (questionIndex, optionIndex) => {
        setTableData((prevData) => {
            const newData = { ...prevData };
            newData.answer3[questionIndex].options = newData.answer3[questionIndex].options.map((option, index) => ({
                ...option,
                value: index === optionIndex
            }));
            return newData;
        });
    };


    const handleInputChange = (id, value) => {
        const parsedValue = parseInt(value, 10);

        if (value === '' || (!isNaN(parsedValue) && parsedValue >= 1 && parsedValue <= 10)) {
            setBargainingItem((prevFormData) => ({
                ...prevFormData,
                answer2: prevFormData.answer2.map((item) =>
                    item.id === id ? { ...item, value } : item
                ),
            }));
        }
    };





    const handleCheckboxChange = (index) => {
        const updatedAnswer = [...bargainingUnit.answer1];
        updatedAnswer[index].is_checked = !updatedAnswer[index].is_checked;
        setBargainingUnit({ ...bargainingUnit, answer1: updatedAnswer });
    };

    const surveySave = () => {
        const isBargainingUnitValid = bargainingUnit.answer1.some(option => option.is_checked);
        if (!isBargainingUnitValid) {
            toast.error("Please select at least one option in the Bargaining Unit section.", { containerId: "S" })
            return;
        }
        const isBargainingItemValid = bargainingItem.answer2.every(item => item.value >= 1 && item.value <= 10);
        if (!isBargainingItemValid) {
            toast.error("Please enter valid values (between 1 and 10) for all items in the Bargaining Item section.", { containerId: "S" })
            return;
        }
        const isTableDataValid = tableData.answer3.every(item => item.options.some(option => option.value));
        if (!isTableDataValid) {
            toast.error("Please select a radio option for each item in the Table Data section.", { containerId: "S" })
            return;
        }
        console.log(surveyData);
        setBargainingUnit({
            question1: "Bargaining Unit",
            answer1: [
                { option: "OFFICE", is_checked: false },
                { option: "OUTSIDE", is_checked: false },
                { option: "PROFESSIONAL AND TECHNICAL", is_checked: false },
                { option: "TEMPORARY", is_checked: false }
            ]
        });
        setBargainingItem({
            question2: "PLEASE RANK YOUR BARGAINING ITEMS (from 1 to 10 with 1 being the highest priority and 10 being the lowest priority)",
            answer2: [{
                id: 'bargainingItem1',
                label: 'WAGES/JOB EVALUATION',
                value: ''
            },
            {
                id: 'bargainingItem2',
                label: 'BENEFIT ENHANCEMENTS',
                value: ''
            },
            {
                id: 'bargainingItem3',
                label: 'JOB POSTING LANGUAGE IMPROVEMENTS',
                value: ''
            },
            {
                id: 'bargainingItem4',
                label: 'PREMIUM, ALLOWANCE AND ALLOTMENT INCREASES',
                value: ''
            },
            {
                id: 'bargainingItem5',
                label: 'APPROVED LEAVE IMPROVEMENTS (ie Bereavement, Parental/Maternity, Personal leave)',
                value: ''
            },
            {
                id: 'bargainingItem6',
                label: 'HOURS OF WORK AND SCHEDULING (Compressed, Flex, Remote work)',
                value: ''
            },
            {
                id: 'bargainingItem7',
                label: 'HEALTH AND SAFETY IMPROVEMENTS',
                value: ''
            },
            {
                id: 'bargainingItem8',
                label: 'SICK LEAVE',
                value: ''
            },
            {
                id: 'bargainingItem9',
                label: 'VACATION',
                value: ''
            },
            {
                id: 'bargainingItem10',
                label: 'SENIORITY/JOB SECURITY',
                value: ''
            }]
        });
        setTableData({
            question3: "PLEASE RATE THESE ITEMS IN TERMS OF IMPORTANCE TO IMPROVING TO YOU",
            answer3: [
                {
                    question: "SHIFT PREMIUMS",
                    options: [
                        { id: 'shiftPrmius1', label: 'IMPORTANT', value: false },
                        { id: 'shiftPrmius2', label: 'SOMEWHAT IMPORTANT', value: false },
                        { id: 'shiftPrmius3', label: 'NEUTRAL', value: false },
                        { id: 'shiftPrmius4', label: 'SOMEWHAT UNIMPORTANT', value: false },
                        { id: 'shiftPrmius5', label: 'UNIMPORTANT', value: false }
                    ]
                },
                {
                    question: "HEALTH CARE COVERAGE",
                    options: [
                        { id: 'healthCare1', label: 'IMPORTANT', value: false },
                        { id: 'healthCare2', label: 'SOMEWHAT IMPORTANT', value: false },
                        { id: 'healthCare3', label: 'NEUTRAL', value: false },
                        { id: 'healthCare4', label: 'SOMEWHAT UNIMPORTANT', value: false },
                        { id: 'healthCare5', label: 'UNIMPORTANT', value: false }
                    ]
                },
                {
                    question: "POST RETIREMENT BENEFITS",
                    options: [
                        { id: 'retirement1', label: 'IMPORTANT', value: false },
                        { id: 'retirement2', label: 'SOMEWHAT IMPORTANT', value: false },
                        { id: 'retirement3', label: 'NEUTRAL', value: false },
                        { id: 'retirement4', label: 'SOMEWHAT UNIMPORTANT', value: false },
                        { id: 'retirement5', label: 'UNIMPORTANT', value: false }
                    ]
                },
                {
                    question: "PARAMEDICAL BENEFITS (IE MASSAGE, CHIROPRACTIC, PHYSIO ETC)",
                    options: [
                        { id: 'paramedical1', label: 'IMPORTANT', value: false },
                        { id: 'paramedical2', label: 'SOMEWHAT IMPORTANT', value: false },
                        { id: 'paramedical3', label: 'NEUTRAL', value: false },
                        { id: 'paramedical4', label: 'SOMEWHAT UNIMPORTANT', value: false },
                        { id: 'paramedical5', label: 'UNIMPORTANT', value: false }
                    ]
                },
                {
                    question: "RETENTION PAY",
                    options: [
                        { id: 'retention1', label: 'IMPORTANT', value: false },
                        { id: 'retention2', label: 'SOMEWHAT IMPORTANT', value: false },
                        { id: 'retention3', label: 'NEUTRAL', value: false },
                        { id: 'retention4', label: 'SOMEWHAT UNIMPORTANT', value: false },
                        { id: 'retention5', label: 'UNIMPORTANT', value: false }
                    ]
                }
            ]
        });
        setComments({
            question4: "Any other comments",
            answer4: ''
        });
        window.scrollTo(0, 0)
        const postData = {
            "functionName": "saveOrUpdateOfferData",
            "offerDataId": "",
            "moduleTypeId": "2",
            "siteId": window.localStorage.getItem("siteId"),
            "createdOn": new Date().getTime(),
            "offerDataValue": surveyData
        };
        console.log("postData", postData);
        dispatch(registerMail(postData)).then((data) => {
            console.log("postData", postData);
            if (data.payload.success == "1") {
                document.getElementById("surveyDiv").style.display = "none";
                document.getElementById("thankYouDiv").style.display = "block";
            }

        })
    };
    return (
        <div className="register-main-wrapper">
              <ToastContainer
                enableMultiContainer
                position="bottom-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                containerId={"S"}
                theme="colored" />
            <Suspense fallback={<div></div>}>
                <div className="container-fluid outer-padding registration-wrapper header-position">
                    <Header />
                    <div className="container" id="surveyDiv">
                        <h3 className="login_head mb-3 mt-5 text-center" style={{ color: " #eb2229" }}> Bargaining Survey</h3>
                        <div className="survey-fromOuter">
                            <div className="form-div-sec">
                                <div className="lbl-text">1. Bargaining Unit<span style={{ color: "red" }}>*</span></div>
                                <div className="pl-3 pt-2">
                                    {bargainingUnit.answer1.map((option, index) => (
                                        <div key={index} className="form-group mb-0">
                                            <input
                                                type="checkbox"
                                                id={option.option}
                                                checked={option.is_checked}
                                                onChange={() => handleCheckboxChange(index)}
                                            />
                                            <label htmlFor={option.option}>{option.option.toUpperCase()}</label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="form-div-sec">
                                <div className="lbl-text" style={{ fontSize: "16px" }}>2. PLEASE RANK YOUR BARGAINING ITEMS (from 1 to 10 with 1 being the highest priority
                                    and 10 being the lowest priority)<span style={{ color: "red" }}>*</span></div>
                                <div className="pl-3 pt-2">

                                    {bargainingItem.answer2.map((item) => (
                                        <div key={item.id} className="form-div-items">
                                            <input
                                                type="number"
                                                name={item.id}
                                                className="input-number"
                                                value={item.value}
                                                onChange={(e) => handleInputChange(item.id, e.target.value)}
                                                min="1"
                                                max="10"
                                            />
                                            <span htmlFor={item.id} className="form-check-label">
                                                {item.label}
                                            </span>
                                        </div>
                                    ))}

                                </div>
                            </div>

                            <div className="form-div-sec items-rateouter">
                                <div className="lbl-text pb-2" style={{ fontSize: "16px" }}>3. PLEASE RATE THESE ITEMS IN TERMS OF IMPORTANCE TO IMPROVING TO YOU<span style={{ color: "red" }}>*</span></div>
                                <div className="table-responsive">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col"></th>
                                                <th scope="col">IMPORTANT</th>
                                                <th scope="col">SOMEWHAT IMPORTANT</th>
                                                <th scope="col">NEUTRAL</th>
                                                <th scope="col">SOMEWHAT UNIMPORTANT</th>
                                                <th scope="col">UNIMPORTANT</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tableData.answer3.map((item, questionIndex) => (
                                                <tr key={item.question}>
                                                    <td className="td-widthsec">{item.question}</td>
                                                    {item.options.map((option, optionIndex) => (
                                                        <td className="text-center" key={option.id}>
                                                            <div className="form-check" onClick={() => handleRadioChange(questionIndex, optionIndex)}>
                                                                <input
                                                                    type="radio"
                                                                    name={`radio-${questionIndex}`}
                                                                    id={`${option.id}-${questionIndex}`}
                                                                    checked={option.value}
                                                                // onChange={() => { }}
                                                                />
                                                                <span className="custom-span"></span>
                                                            </div>

                                                        </td>
                                                    ))}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="form-div-sec">
                                <div className="lbl-text pb-2">4. Any other comments</div>
                                <div className="form-label-group">
                                    <textarea
                                        className="form-control textareaText"
                                        placeholder="Any other comments"
                                        autoComplete="off"
                                        value={comments.answer4}
                                        onChange={handleCommentsChange}
                                    />
                                </div>
                            </div>

                            <div className="d-flex" style={{ justifyContent: "right" }}>
                                <button className="btn btn-lg btn-block cancel_btn mr-2" style={{ width: "150px" }} type="cancel">Cancel</button>
                                <button className="btn btn-lg btn-block login_btn mt-0" style={{ width: "150px" }} type="submit" onClick={() => surveySave()}>Save</button>
                            </div>
                        </div>
                    </div>

                    <div className="container" id="thankYouDiv" style={{ display: "none" }} >
                        <div className="surveyThanku">
                            <div className="">
                                <h3 className="surveyThanku-head">Thank You !!</h3>
                                <h4 className="surveyThanku-sub"> We will keep your information safe.</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </Suspense>
        </div>
    );
};

export default Survey;
