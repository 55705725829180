import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { saveData } from "../../service";


export const executiveListing = createAsyncThunk("/getExecutives-Listing", async (parameter, thunkAPI) => {
    try {
        let resp = await saveData(parameter);
        return resp;
    } catch (err) {
        throw err;
    }
});
export const deleteExecutives = createAsyncThunk("/delete-Executives", async (parameter, thunkAPI) => {
    try {
        let resp = await saveData(parameter);
        return resp;
    } catch (err) {
        throw err;
    }
});
export const addExecutive = createAsyncThunk("/executiveRegistration", async (parameter, thunkAPI) => {
    try {
        let resp = await saveData(parameter);
        return resp;
    } catch (err) {
        throw err;
    }
});

export const executiveSlice = createSlice({
    name: "executive",
    initialState: {
        executiveList: [],
        executiveListCount: "",
        deleteExecutivesResult: false,
        addExecutiveResult: false,

    },

    reducers: {

    },
    extraReducers: (builder) => {


        builder.addCase(executiveListing.fulfilled, (state, action) => {
            console.log("action.payload.result.executivesData", action.payload.result.executivesData)
            state.executiveList = action.payload.result.executivesData;
            state.executiveListCount = action.payload.totalCount
            state.loading = false;
        });
        builder.addCase(executiveListing.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(executiveListing.rejected, (state) => {
            state.executiveList = [];
            state.loading = false;
        });

        builder.addCase(deleteExecutives.fulfilled, (state, action) => {
            if (action.payload.success == 1) {
                state.deleteExecutivesResult = true
            }
            state.loading = false;
        });
        builder.addCase(deleteExecutives.pending, (state) => {
            state.deleteExecutivesResult = false
            state.loading = true;
        });
        builder.addCase(deleteExecutives.rejected, (state) => {
            state.deleteExecutivesResult = false
            state.loading = false;
        });

        builder.addCase(addExecutive.fulfilled, (state, action) => {
            state.loading = false;
            if (action.payload.success == 1) {
                state.addExecutiveResult = true
            }
        });
        builder.addCase(addExecutive.pending, (state) => {
            state.addExecutiveResult = false
            state.loading = true;
        });
        builder.addCase(addExecutive.rejected, (state) => {
            state.addExecutiveResult = false
            state.loading = false;
        });

    }



});


export default executiveSlice.reducer;
