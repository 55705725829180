import React,{useEffect,useState} from "react";
// import BannerImg from '../images/equalityBanner.png';
// import Cupelogo from '../images/cupe.png';
import { printParams } from "../config";
// import { homePageListing } from '../redux/reducers/homePageSlice';
// import {useSelector } from 'react-redux';
const Banner = (bannerBottom) => {
    var getS3folder = process.env.REACT_APP_S3_BUCKET_URL+process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE + process.env.REACT_APP_CUPETHEME_PATH
    const [equalityBanner, setEqaualityBanner] = useState("");
    const [equalityHead, setEqaualityHead] = useState("");
    // const [equalityBanner, setEqaualityBanner] = useState("");
    useEffect(() => {
console.log("dddddddddddddddddd",bannerBottom)

const bannerThemeData = bannerBottom.bannerBottom[0];
printParams("bannerData[0]", bannerThemeData);
if (bannerThemeData && bannerThemeData.themeData) {
  try {
      const bannerDatas = JSON.parse(bannerThemeData.themeData);
      printParams("bannerDatasbunch", bannerDatas);
      // setbannerDetails(bannerDatas)
      setEqaualityBanner(bannerDatas[0].equalityImage)
      setEqaualityHead(bannerDatas[0].equalityHeading)
      // setBannerSubHeading(bannerDatas[0].bannerSubHeading)
  } catch (error) {
      printParams("Error parsing bannerData:", error);
  }
} else {
  printParams("bannerData or bannerData is undefined.");
}
    }, [bannerBottom]);
    
  const bannerStyle = {
    backgroundImage:
      localStorage.getItem("themeName") === "BunchBerry" ||
      localStorage.getItem("themeName") === "Mackenzie"
        ? `url(${getS3folder}/${equalityBanner})`
        : "none",
    backgroundColor:
      localStorage.getItem("themeName") === "BunchBerry" ||
      localStorage.getItem("themeName") === "Mackenzie"
        ? "red"
        : "",
    backgroundBlendMode:
      localStorage.getItem("themeName") === "BunchBerry" ||
      localStorage.getItem("themeName") === "Mackenzie"
        ? "multiply"
        : "normal",
  };

  const imgStyle = {
    visibility:
      localStorage.getItem("themeName") === "BunchBerry" ||
      localStorage.getItem("themeName") === "Mackenzie"
        ? "hidden"
        : "visible",
  };

  return (
    <div className="Bannerdiv Bannerdiv-equality mt-2" style={bannerStyle}>
      <img src={getS3folder+"/"+ equalityBanner} alt="Banner" className="img-fluid equality-banner-img" width="900" height="500" style={imgStyle}/>
      {/* <div className="banner-text">
        Cupe 831
      </div> */}
      <div className="banner-text-2">
        <div className="">
          {equalityHead}
        </div>
        </div>
      {/* <div className="cupe-sub-logo">
        <img src={Cupelogo} alt="Cupelogo" className="img-fluid" width="144" height="144" />
      </div> */}
    </div>
  );
};

export default Banner;
