import React, { useState, useEffect, useRef, Suspense } from "react";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";
import { loginView } from "../redux/reducers/loginSlice";
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import { masterDataListing, signUpregistration } from '../redux/reducers/masterSlice';
import CryptoJS from 'crypto-js';
import UserImg from '../images/user.png'
import { domainFilterDetailList } from '../redux/reducers/domainFilterSlice';
let Header = React.lazy(() => import("../" + localStorage.getItem("themeName") + "/header"));
let Footer = React.lazy(() => import("../" + localStorage.getItem("themeName") + "/footer"));
var themeName = '';
const MemberLogin = () => {
  const dispatch = useDispatch();
  const { masterPageDataListing, masterPageFlag } = useSelector((state) => state.masterPage);
  const { domainFilterList } = useSelector((state) => state.domainFilter);
  const [isActive, setIsActive] = useState(false);
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [divShow, setdivShow] = useState(false)
  const [UsernameOrEmail, setUsernameOrEmail] = useState("Username or Email")
  const [loginOrSendMail, setloginOrSendMail] = useState("Log In")
  const [forgotPassword, setForgotPassword] = useState(true)
  const [logo, setLogo] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [personalEmail, setPersonalEmail] = useState("");
  const [cellPhone, setCellPhone] = useState("");
  const [address, setAddress] = useState("");
  const [saveFlag, setsaveFlag] = useState(true);
  const [currentDateTime] = useState(new Date().getTime());
  const elementRef = useRef();
  const toggleClass = () => {
    setIsActive((prevState) => !prevState);
    document.body.classList.add('body_overflow');
  };
  const closeMenu = () => {
    setIsActive(false);
    document.body.classList.remove('body_overflow');
  };
  const handleKeypress = (event) => {
    if (event.key === "Enter") {
      cupeLoginHandler();
    }
  };
  useEffect(() => {    
    listing()
    setLogo(masterPageDataListing)
    // $('#equality').click(function() {
    //   const element = document.getElementById("equality-statement");
    //   if (element) {
    //     $('html, body').animate({
    //       scrollTop: $(element).offset().top
    //     }, 1000);
    //   }
    // });
    $('.fixed_menu_inner a').click(function () {
      document.body.classList.remove('body_overflow');
    })
    const handleClickOutside = (e) => {
      if (elementRef.current && !elementRef.current.contains(e.target)) {
        setdivShow(false);
      }
    };
    document.addEventListener('mouseup', handleClickOutside);

    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, []);
  const listing = async () => {
    try {
      const result = await dispatch(
        masterDataListing({
          "functionName": "getSiteDetails",
          "siteId": "1",
          "siteUrl": ""
        })
      );
      //themeName = result.payload.result.siteData[0].themeName
      
      if(localStorage.getItem("themeName") === null || localStorage.getItem("themeName") === "null" || localStorage.getItem("themeName") === ""){
        localStorage.setItem("themeName",result.payload.result.siteData[0].themeName)
        window.localStorage.setItem("siteId","1")
        window.location.reload()
      }
      
      // localStorage.setItem("themeName","GrayJay")
      //   if(masterPageDataListing.length>0){
      setLogo(result.payload.result.siteData)
      //   }

    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  const className = isActive ? 'active' : '';
  const cupeLoginHandler = () => {
    let flag = true;
    var phoneno = /^[0-9]+$/;
    var num = /^\d{10}$/;
    var email = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g;
    if (forgotPassword) {
      if (userName === "") {
        toast.error('Username /Email ID required', { containerId: "M" })
        document.getElementById('userName').focus();
        flag = false;
      }
      // else if (userName.match(phoneno) && !userName.match(num)) {
      //   alert("Please enter valid mobile number");
      //   document.getElementById("userName").focus();
      //   flag = false;
      // } 
      // else if (!userName.match(phoneno) && !email.test(userName)) {
      //   alert("Please enter valid Email ID/Mobile Number");
      //   document.getElementById("userName").focus();
      //   flag = false;
      // }
      else if (!password) {
        toast.error("Please enter Password", { containerId: "M" })
        document.getElementById("password").focus();
        flag = false;
      }
      if (flag) {
        const data = {
          username: userName,
          password: password,
        };
        // var data="U2FsdGVkX18FqA1RjRo13AzYGiUQq4L/HwHVXpnQPbc"//eZO97YNMmC2TjhEQKZM2EmLtZZACUIT0Z5AtOaDYlnOXLgtrkKZ3+/wrRG8/Mw="
        var REACT_APP_ENCRYPTION_KEY = "C#U@@PE"
        const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), REACT_APP_ENCRYPTION_KEY).toString();
        const bytes = CryptoJS.AES.decrypt(ciphertext, REACT_APP_ENCRYPTION_KEY);
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        const postData = {
          "functionName": "login",
          "token": ciphertext
        }
        dispatch(loginView(postData)).then((data) => {
          if (data.payload.success == "1"
          ) {
            toast.success("Successfully loggedIn", { containerId: "M" })
            //  let  encryptedData=data.payload.redirectUrl.split("/").pop()
            //  window.open("http://localhost:3000/"+encryptedData, "_blank");
            // window.open(data.payload.redirectUrl, "_blank");
            window.location.href = data.payload.redirectUrl
            // console.log(data.payload.redirectUrl, "oooooooooooooooooooo");
            // if (data.payload.redirectUrl) {
            //   const PostData = {
            //     "functionName": "getUserDetailsByToken",
            //     "encryptedData": data.payload.redirectUrl
            //   }
            //   dispatch(loginView(PostData)).then((data) => {
            //     console.log(data, "uuuuuuuuuuuuuuuuuuuuuuuuu");
            //   }
            //   )
            // }
          } else {
            toast.error(data.payload.errorMessage, { containerId: "M" })
            // window.history()
          }
        })
      }
    } else {
      if (userName === "") {
        toast.error("Email ID required", { containerId: "M" })
        // alert('Email ID required');
        document.getElementById('userName').focus();
        flag = false;
      }
      else if (!userName.match(email)) {
        toast.error("Please enter valid Email Id", { containerId: "M" })
        // alert("Please enter valid Email Id");
        document.getElementById("userName").focus();
        flag = false;
      } else {
        const Data =
        {
          "functionName": "forgotPassword",
          "siteId": window.localStorage.getItem("siteId"),
          "userName": userName
        }
        //   {
        //     "functionName": "forgotPassword",
        //     "siteId": window.localStorage.getItem("siteId"),
        //     "personalEmail":userName   
        // }
        dispatch(loginView(Data)).then((data) => {
          if (data.payload.success === "1") {
            toast.success("Link Send To EMail ID Successfully", { containerId: "M" })
          }
          else { toast.error("Something Went Wrong", { containerId: "M" }) }
        }
        )
      }
    }
  }


  const loginFlagOnchange = () => {
    document.getElementById("memberLoginSignUp").style.display = "none"
    document.getElementById("backToLogin").style.display = "none"
    document.getElementById("memberLogin").style.display = "block"
    document.getElementById("cupeLogin").style.display = "block"
    document.getElementById("cupeLoginhaveanaccount").style.display = "block"
    setUserName("");
    setFirstName("");
    setLastName("");
    setPersonalEmail("");
    setCellPhone("");
    setAddress("");
  }
  const signUpFlagOnchange = () => {
    document.getElementById("memberLoginSignUp").style.display = "block"
    document.getElementById("backToLogin").style.display = "block"
    document.getElementById("memberLogin").style.display = "none"
    document.getElementById("cupeLogin").style.display = "none"
    document.getElementById("cupeLoginhaveanaccount").style.display = "none"
  }

  useEffect(() => {
    dispatch(domainFilterDetailList({
      "functionName": "getDomainFilterDetails",
      "searchCase": "",
      "searchValue": "",
      "offset": "0",
      "max": "",
      "domainFilterId": ""
    }));

  }, [])

  const signUp = () => {
    debugger
    setsaveFlag(false)
    var flag = true
    var characters = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var splitemail = personalEmail.split(/\r|\r\n|\n/);
    var emailcount = splitemail.length
    var spliteUnionEmail = userName.split(/\r|\r\n|\n/);
    var unionEmailcount = spliteUnionEmail.length
    // const emailRegex = /@brampton\./;

    const emailRegex = personalEmail
    console.log(personalEmail, "personalEmail");
    const domainNames = domainFilterList.map(item => item.name);
    const splitDomainNames = emailRegex.split('@')[1];
    console.log(domainNames, "domainNames");
    console.log(domainFilterList, "domainFilterList");
    console.log(splitDomainNames, "splitDomainNames");

    if (firstName === "") {
      toast.error("First Name Required", { containerId: "M" })
      document.getElementById('firstName').focus();
      setsaveFlag(true)
      flag = false;
    }
    else if (personalEmail === '') {
      toast.error("Personal Email Required", { containerId: "M" })
      document.getElementById('personalEmail').focus();
      setsaveFlag(true)
      flag = false;
    }
    else if (characters.test(splitemail[emailcount - 1]) === false || domainNames.includes(splitDomainNames)) {
      //else if (characters.test(splitemail[emailcount - 1]) === false) {
      toast.error("Invalid Personal Email", { containerId: "M" })
      document.getElementById('personalEmail').focus();
      setsaveFlag(true)
      flag = false;
    }
    else if (cellPhone === '') {
      toast.error("Cell Phone Number Required ", { containerId: "M" })
      document.getElementById('cellphone').focus();
      setsaveFlag(true)
      flag = false;
    }
    else if (address === '') {
      toast.error("Address Required ", { containerId: "M" })
      document.getElementById('address').focus();
      setsaveFlag(true)
      flag = false;
    }
    if (flag === true) {
      toast.success("Your information has been saved. Once the admin approves it, you will receive a notification in your personal email.", { containerId: "M" })
      const postData =
      {
        "functionName": "memberRegistration",
        // "functionName": "userRegistration",
        "userName": personalEmail,
        "firstName": firstName,
        "lastName": lastName,
        "createdDateTime": currentDateTime,
        "personalEmail": personalEmail,
        "cellPhoneNumber": cellPhone,
        "homePhoneNumber": "",
        "address": address,
        "city": "",
        "province": "",
        "postalCode": "",
        "employeeId": "",
        "unionEmail": "",
        "password": "",
        "dateOfBirth": "",
        "ROLE_NAME": "MEMBER",
        "unitId": "",
        "unitName": "",
        "jobTitleId": "",
        "jobTitleName": "",
        "siteId": 1
        // "siteId": window.sessionStorage.getItem("selectedSiteId") ? window.sessionStorage.getItem("selectedSiteId") : window.sessionStorage.getItem("siteId"),
      }

      dispatch(signUpregistration(postData)).then((data) => {
        if (data.payload.success === "1") {
          toast.success("Your information has been saved. Once the admin approves it, you will receive a notification in your personal email.", { containerId: "M" })
          setUserName("");
          setFirstName("");
          setLastName("");
          setPersonalEmail("");
          setCellPhone("");
          setAddress("");
          setsaveFlag(true)
          // createMemberEmail(data.payload.result.userId)
          // dispatch(addMember(data.payload.result)).unwrap()
        }
        else {
          toast.success(data.payload.successMessage, { containerId: "M" })
          // document.getElementById('personalEmail').focus();
          setsaveFlag(true)
        }
      })
    }
  };
  return (
    <div className="footer_white">
      <Suspense fallback={<div></div>}>
        <div className="container-fluid outer-padding contact-wrapper  header-position">
          <Header />

          <ToastContainer
            enableMultiContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            containerId={"M"}
            theme="colored" />
          <>
            <div className="login_inner" id="memberLogin">
              <h1 className="login_head">
                Login
              </h1>
              <div className="login_form">
                <div className="form-label-group">
                  <input type="email" id="userName" name="userName" value={userName} className="form-control" placeholder={UsernameOrEmail} onKeyPress={handleKeypress}
                    onChange={(e) => setUserName(e.target.value)}
                    autoComplete="off" required autofocus />
                  <label for="inputEmail">{UsernameOrEmail}</label>
                </div>
                {forgotPassword &&
                  <div className="form-label-group">
                    <input type="password" id="password" name="password" value={password} className="form-control" placeholder="Password" onKeyPress={handleKeypress}
                      onChange={(e) => setPassword(e.target.value)}
                      autoComplete="off" required autofocus />
                    <label for="inputEmail">Password</label>
                  </div>}
                <button className="btn btn-lg btn-block login_btn" type="submit" id="cupeLogin" onClick={() => cupeLoginHandler()}>{loginOrSendMail}</button>
                {forgotPassword ?
                  <a href="#" onClick={(e) => {
                    setForgotPassword(!forgotPassword)
                    if (forgotPassword) {
                      setUsernameOrEmail("Please Fill Recover Email ")
                      setloginOrSendMail("Send Mail")
                    }
                    else {
                      setUsernameOrEmail("Username or Email")
                      setloginOrSendMail("Log In")
                    }
                  }
                  } className="login_forgot">Forgot Your password?</a>
                  :
                  <a href="#" onClick={(e) => {
                    if (forgotPassword) {
                      setUsernameOrEmail("Please Fill Recover Email ")
                      setloginOrSendMail("Send Mail")
                    }
                    else {
                      setUsernameOrEmail("Username or Email")

                      setloginOrSendMail("Log In")
                    }
                    setForgotPassword(!forgotPassword)
                  }
                  }
                    className="login_forgot">Go Back</a>}
                {/* <div className="logn-acc-text">
                  Already don't have an account?  <a className="login_forgot mt-0 ml-1" onClick={(e) => signUpFlagOnchange()}>SignUp</a>
                </div> */}
              </div>
            </div>
            <div className="login_inner" id="memberLoginSignUp" style={{ display: "none" }}>
              <h1 className="login_head">
                Member Sign Up
              </h1>
              <div className="login_form">
                <div className="form-label-group">
                  <input type="text" id="firstName" name="firstName" className="form-control" placeholder={"FirstName"}
                    autoComplete="off" value={firstName}
                    onChange={(e) => setFirstName(e.target.value)} required autofocus />
                  <label for="firstName">First Name*</label>
                </div>
                <div className="form-label-group">
                  <input type="text" id="lastName" name="lastName" className="form-control" placeholder={"LastName"}
                    autoComplete="off" value={lastName}
                    onChange={(e) => setLastName(e.target.value)} required autofocus />
                  <label for="firstName">Last Name</label>
                </div>
                <div className="form-label-group">
                  <input type="email" id="personalEmail" name="personalEmail" className="form-control" placeholder={"Personal Email"}
                    autoComplete="off" value={personalEmail}
                    onChange={(e) => setPersonalEmail(e.target.value)} required autofocus />
                  <label for="firstName">Personal Email*</label>
                </div>
                <div className="form-label-group">
                  <input type="text" id="cellphone" name="cellphone" className="form-control" placeholder={"Cellphone"}
                    autoComplete="off" required autofocus value={cellPhone}
                    maxLength={15}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={(e) => setCellPhone(e.target.value)} />
                  <label for="firstName">Cellphone*</label>
                </div>
                <div className="form-label-group">
                  <textarea id="address" name="address" className="form-control" placeholder={"Address*"}
                    autoComplete="off" value={address}
                    onChange={(e) => setAddress(e.target.value)} required autofocus />
                  {/* <label for="address">Address*</label> */}
                </div>
                <span className="btn btn-lg btn-block login_btn" id="cupeLoginSighUp" type="submit" onClick={saveFlag == false ? null : () => signUp()}>Sign Up</span>
                <div className="logn-acc-text" id="backToLogin" style={{ display: "none" }}>
                  <a className="login_forgot text-center" style={{ color: "#000" }} onClick={(e) => loginFlagOnchange()}>back to login</a>
                </div>


              </div>
              <div id="cupeLoginhaveanaccount">
                Already have an account?  <a className="login_forgot">Login</a>
              </div>
            </div>
          </>

        </div>
        <Footer />
      </Suspense>
    </div>
  );
};

export default MemberLogin;
