import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { saveData } from "../../service";

export const newsListing = createAsyncThunk("/newsListing", async (parameter, thunkAPI) => {
    try {
        let resp = await saveData(parameter);
        return resp;
    } catch (err) {
        throw err;
    }
});
export const newSlice = createSlice({
    name: "newsList",
    initialState: {
        newsListData: [],
    },

    reducers: {

    },
    extraReducers: (builder) => {


        builder.addCase(newsListing.fulfilled, (state, action) => {
            console.log("news", action.payload)
            state.newsListData=action.payload.result.newsData;
            state.loading = false;
        });
        builder.addCase(newsListing.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(newsListing.rejected, (state) => {
            state.newsListData = [];
            state.loading = false;
        });

    }



});


export default newSlice.reducer;