import React,{useEffect,useState} from "react";
import Cupelogo from '../images/cupe.png';
import { homePageListing } from '../redux/reducers/homePageSlice';
import * as Icon from "react-feather";
import { useDispatch, useSelector } from 'react-redux';

function Banner() {
    const dispatch = useDispatch()
    const [contactDetails, setContactDetails] = useState([])
    useEffect(() => {
   listing()
    }, []);
    const listing = async () => {
        try {
            const resultAction = await dispatch(homePageListing({
                "functionName": "getThemeContentData",
                "siteId": window.localStorage.getItem("siteId"),
            })).unwrap();
            const result = resultAction.result.themeContentData
            const filteredData = result.filter(item => item.themeSectionId === 5);
            const contactDatas = JSON.parse(filteredData[0].themeData);
            setContactDetails(contactDatas)
        } catch (error) {
            console.error("Error occurred:", error);
        }
    }
    return (
        <div className="Bannerdiv Bannerdiv-contact mt-2">
            {contactDetails.map((obj, i) => (
            <>
            <img src={process.env.REACT_APP_S3_BUCKET_URL+process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE + process.env.REACT_APP_CUPETHEME_PATH+'/'+localStorage.getItem("themeName")+'-'+"contactBanner.png"} alt="Banner" className="img-fluid img-fullwidth Bannerdiv-contact-img" width="1200" height="400"/>
            
            <div className="bannerdiv-contact-text">
                {localStorage.getItem("themeName") === "MapleLeaf" && (
                    <div className="banner-text">
                        Cupe 831
                    </div>
                )}
                <div className="banner-text-2">
                   {/* <span className="get_in_theme_color">Get In</span>  Touch */}
                   <span className="get_in_theme_color">Contacts</span>
                </div>
                {localStorage.getItem("themeName") === "MapleLeaf" && (
                    <div className="cupe-sub-logo">
                        <img src={Cupelogo} alt="Cupelogo" className="img-fluid" width="100" height="100"/>
                    </div>
                )}
                <div className="banner-text-3">
                    <span className="banner-phone-text">
                        <Icon.MapPin/>
                        <span>{obj.contactUsAddress + ", " + obj.contactUsPostalCode}</span>
                    </span>
                    <span className="banner-phone-text">
                        <Icon.Phone/>
                        <span>{obj.contactUsPhoneNumber}</span>
                    </span>
                </div>
            </div>
            </>
            ))}
        </div>
    );
}

export default Banner;
