import React, { useEffect, useRef ,useState, Suspense } from "react";
// import Header from "../MapleLeaf/header";
import Banner from "./equalityBanner";
import Statement from "./statements";
import BottomSection from "./bottomSection";
// import Footer from "../MapleLeaf/footer";
import $ from 'jquery';

import { printParams } from "../config";
import { homePageListing } from '../redux/reducers/homePageSlice';
import { useDispatch, useSelector } from 'react-redux';
let Header = React.lazy(() => import("../" + localStorage.getItem("themeName") + "/header"));
let Footer = React.lazy(() => import("../" + localStorage.getItem("themeName") + "/footer"));
const EqualityStatement = () => {

    const equalityRef = useRef(null);
    const [bannerBottom, setBannerBottom] = useState([]);
    const [bannerStatementShort, setBannerStatementShort] = useState("");
    const [bannerStatementLong, setBannerStatementLong] = useState("");
    const [aboutData, setAboutData] = useState([]);
  
    const dispatch = useDispatch()
    const { homePageDataListing = { themeContentData: [] } } = useSelector((state) => state.homePage);
    useEffect(() => {
        printParams(" localStorage.getItemccccccccccccc",  import("../" + localStorage.getItem("themeName") + "/header"))
        $(equalityRef.current).addClass("active").css("color", "#000");
    }, []);
    useEffect(() => {
		$("#equality").addClass("active").css("color", "#000");
        listing()
    }, []);
    useEffect(() => {
        const { themeContentData } = homePageDataListing;
        printParams(" localStorage.getItem",  homePageDataListing)

        // Check if themeContentData is defined before using filter
        if (themeContentData) {
            const filteredData = themeContentData.filter(item => item.themeSectionId === 2);
            printParams("filteredData", filteredData);
            setAboutData(filteredData)
            const filteredbannerBottom = themeContentData.filter(item => item.themeSectionId === 4);
            printParams("filteredbannerBottom", filteredbannerBottom);
            setBannerBottom(filteredbannerBottom)
        }
        $("#equality").addClass("active").css("color", "#000");
    }, [homePageDataListing]);
    const listing = async () => {
        try {
            const resultAction = await dispatch(homePageListing({
                "functionName": "getThemeContentData",
                "siteId": window.localStorage.getItem("siteId"),
            })).unwrap();
        } catch (error) {
            // Handle errors here
            console.error("Error occurred:", error);
        }
    }
    return (
        <div className="footer_white">
             <Suspense fallback={<div></div>}>
            <div className="container-fluid equalityStatment-wrapper header-position">
                <Header />
                <Banner bannerBottom={bannerBottom}/>
                <Statement bannerBottom={bannerBottom}/>
                <BottomSection aboutData={aboutData}/>
            </div>
            <Footer />
            </Suspense>
        </div>
    );
};

export default EqualityStatement;
