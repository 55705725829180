import React, { Suspense, useEffect, useState } from "react";
import RegisterImg from '../images/register.jpg'
import * as Icon from "react-feather";
import "./style.css"
import { registerMail } from "../redux/reducers/loginSlice";
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'
import { domainFilterDetailList } from '../redux/reducers/domainFilterSlice';
let Header = React.lazy(() => import("../" + localStorage.getItem("themeName") + "/header"));
let Footer = React.lazy(() => import("../" + localStorage.getItem("themeName") + "/footer"));


const Register = ({ }) => {
    const { width, height } = useWindowSize()
    const dispatch = useDispatch();
    const { domainFilterList } = useSelector((state) => state.domainFilter);
    const [addmore, setAddmore] = useState(false);
    const [showCongratulations, setShowCongratulations] = useState(false);
    const [test, setTest] = useState(true); // State to control visibility
    const [childName, setChildName] = useState('');
    const [departmentChange, setDepartmentChange] = useState('');
    const [parentLastName, setParentLastName] = useState('');
    const [parentName, setParentName] = useState('');
    const [emailChange, setEmailChange] = useState('');
    const [childAge, setChildAge] = useState('');
    const [childGender, setChildGender] = useState('');
    const [childrenDetails, setChildrenDetails] = useState([]);
    const [shouldValidate, setShouldValidate] = useState(false);
    const [error, setError] = useState('');
    const ageOptions = Array.from({ length: 10 }, (_, i) => i + 1); // Creates an array of numbers from 1 to 18

    const emailOnChange = (value) => {
        setEmailChange(value);
        if (value == "") {
            setShouldValidate(false);
        }
        else {
            setShouldValidate(true);
        }
    };

    useEffect(() => {
        dispatch(domainFilterDetailList({
            "functionName": "getDomainFilterDetails",
            "searchCase": "",
            "searchValue": "",
            "offset": "0",
            "max": "",
            "domainFilterId": ""
        }));

    }, [])


    const validateEmail = (e) => {
        const domainNames = domainFilterList.map(item => item.name);
        const emailValue = e.target.value;

        if (emailValue && emailValue.includes('@')) {
            const splitDomainNames = emailValue.split('@')[1];

            if (domainNames.includes(splitDomainNames)) {
                setError("Invalid Personal Email");
                document.getElementById('emailId').focus();
            } else {
                setError("");
            }
        }

    };

    // const validateEmail = (e) => {
    //     if (e.target.value.includes('brampton.')) {
    //         setError("Invalid Personal Email")
    //         document.getElementById('emailId').focus();
    //         //  setEmailChange('');
    //     } else {
    //         setError("")
    //     }
    // };



    useEffect(() => {
        // setShowCongratulations(true);

    }, []);
    const handleDeleteChild = (index) => {
        const updatedChildren = childrenDetails.filter((child, i) => i !== index);
        setChildrenDetails(updatedChildren);
    };
    const handleAddChild = () => {
        setAddmore(true);
        if (childName.trim() !== "" && childAge !== "" && childGender !== "" && childrenDetails.length != 4) {
            const child = {
                name: childName.trim(),
                age: childAge,
                gender: childGender
            };
            const updatedChildren = [...childrenDetails, child];
            setChildrenDetails(updatedChildren);
            setChildName('');
            setChildAge('');
            setChildGender('');
        }
        else if (childrenDetails.length === 4) {
            toast.error('Only 4 children can be registered', { containerId: "N" })
            setChildName('');
            setChildAge('');
            setChildGender('');

        }
        else if (childName == "" && childAge == "" && childGender == "" && childrenDetails.length != 4) {
            toast.error('Children Details Required', { containerId: "N" })
            document.getElementById("childName").focus();
        }
        else {
            toast.error('Children Details Required', { containerId: "N" })
            document.getElementById("childName").focus();
        }
    };
    const registerCancel = async () => {
        window.location.href = "/"
    }
    const registerSave = async () => {
        debugger
        var email = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g;
        // const emailRegex = /@brampton\.ca$/;
        var characters = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var splitemail = emailChange.split(/\r|\r\n|\n/);
        var personalEmail = emailChange.split(/\r|\r\n|\n/);
        var emailcount = splitemail.length
        var personalEmailCount = personalEmail.length

        const emailRegex = emailChange
        console.log(personalEmail, "personalEmail");
        const domainNames = domainFilterList.map(item => item.name);
        const splitDomainNames = emailRegex.split('@')[1];
        console.log(domainNames, "domainNames");
        console.log(domainFilterList, "domainFilterList");
        console.log(splitDomainNames, "splitDomainNames");

        if (parentName == "") {
            toast.error('First Name Required', { containerId: "N" })
            document.getElementById("firstName").focus();
            return false;
        }
        if (parentLastName == "") {
            toast.error('Last Name Required', { containerId: "N" })
            document.getElementById("lastName").focus();
            return false;
        }
        if (emailChange == "" && shouldValidate == false) {
            toast.error('Personal Email Id Required', { containerId: "N" })
            document.getElementById("emailId").focus();
            return false;
        }
        else if (characters.test(splitemail[emailcount - 1]) === false || domainNames.includes(splitDomainNames)) {
            toast.error("Invalid Personal Email", { containerId: "N" })
            document.getElementById('emailId').focus();
            return false;
        }
        else {
            let updatedChildren = [...childrenDetails]; // Initialize as a copy of the existing childrenDetails

            if (childName.trim() !== "" && childAge !== "" && childGender !== "" && updatedChildren.length !== 4) {
                const child = {
                    name: childName.trim(),
                    age: childAge,
                    gender: childGender
                };
                updatedChildren.push(child); // Add the new child if the input is not empty
            }
            else if (childName !== "" && childAge !== "" && childGender !== "" && updatedChildren.length === 4) {
                toast.error("Only 4 children can be registered", { containerId: "N" })
                setChildName('');
                setChildAge('');
                setChildGender('');
                return false;
            }
            else {
                if (((childName !== "" && childAge == "" && childGender == "") && (childName !== "" && childAge !== "" && childGender == "") && (childName !== "" && childAge == "" && childGender !== "") && (childName == "" && childAge !== "" && childGender !== "") && (childName == "" && childAge == "" && childGender == "")) && updatedChildren.length !== 4) {
                    toast.error("Children Details Required", { containerId: "N" })
                    document.getElementById("childName").focus();
                    return false;
                }
                else if (updatedChildren == "" && (childName == "" && childAge == "" && childGender == "")) {
                    toast.error("Children Details Required", { containerId: "N" })
                    document.getElementById("childName").focus();
                    return false;
                }
                else if (updatedChildren.length <= 4 && (childName !== "" || childAge !== "" || childGender !== "")) {
                    toast.error("Children Details Required", { containerId: "N" })
                    document.getElementById("childName").focus();
                    return false;
                }
            }

            toast.success('Saved Successfully', { containerId: "N" })
            setShowCongratulations(true);
            setTest(false)
            window.scrollTo(0, 0);
            const postData = {
                "functionName": "saveOrUpdateOfferData",
                "offerDataId": "",
                "moduleTypeId": "1",
                "siteId": window.localStorage.getItem("siteId"),
                "createdOn": new Date().getTime(),
                "offerDataValue": {
                    "firstName": parentName,
                    "lastName": parentLastName,
                    "email": emailChange,
                    "company": departmentChange,
                    "childrenDetails": updatedChildren
                }
            }; console.log("postData", postData);
            dispatch(registerMail(postData)).then((data) => {
                if (data.payload.success == "1") {
                    const PostData = {
                        functionName: "createMemberRegistrationMail",
                        memeberId: "",
                        jsonMailOptions: {
                            from: data.payload.result.jsonMailOptions.from,
                            to: data.payload.result.jsonMailOptions.to,
                            subject: data.payload.result.jsonMailOptions.subject,
                            html: data.payload.result.jsonMailOptions.html,
                        },
                        jsonUserMailOptions: {
                            from: data.payload.result.jsonUserMailOptions.from,
                            to: data.payload.result.jsonUserMailOptions.to,
                            subject: data.payload.result.jsonUserMailOptions.subject,
                            html: data.payload.result.jsonUserMailOptions.html,
                        }
                    };
                    dispatch(registerMail(PostData)).then((data) => {
                        if (data.payload.success === "0") { }
                    })
                }

                console.log("postData", postData);
            })

        }
    };

    return (
        <div className="register-main-wrapper">
            <ToastContainer
                enableMultiContainer
                position="bottom-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                containerId={"N"}
                theme="colored" />
            <Suspense fallback={<div></div>}>
                <div className="container-fluid outer-padding registration-wrapper header-position">
                    <Header />
                    {showCongratulations && (
                        <Confetti
                            width={'800px'} numberOfPieces={100}
                        />
                    )}
                    <div class="container">
                        <div className="row">

                            <div class="bannerdiv-contact-text col-lg-5">
                                {showCongratulations && (
                                    <div className="text-center register-topwrap1">

                                        <div >
                                            <Icon.CheckCircle color="green" size={50} />
                                        </div>

                                        <h1 class="login_head">Thank You</h1>
                                        <h4 className="register-success">Your details have been recorded.</h4>
                                    </div>)}
                                <div className="register-topwrap">
                                    {test && (
                                        <>
                                            <h1 class="login_head">Join us</h1>

                                            <div className="login_form">
                                                <div className="form-label-group">
                                                    <input type="email" className="form-control" id="firstName" placeholder="name" autoComplete="off" value={parentName} onChange={(e) => setParentName(e.target.value)} />
                                                    <label for="inputEmail">First Name<span style={{ color: "red", fontSize: "18px" }}>*</span></label>
                                                </div>
                                                <div className="form-label-group">
                                                    <input type="email" className="form-control" id="lastName" placeholder="name" value={parentLastName} autoComplete="off" onChange={(e) => setParentLastName(e.target.value)} />
                                                    <label for="inputEmail">Last Name<span style={{ color: "red", fontSize: "18px" }}>*</span></label>
                                                </div>
                                                <div className="form-label-group">
                                                    <input type="text" className="form-control" placeholder="department" value={departmentChange} autoComplete="off" onChange={(e) => setDepartmentChange(e.target.value)} />
                                                    <label for="inputEmail">Department or division </label>
                                                </div>
                                                <div className="form-label-group">
                                                    <input type="email" id="emailId" className="form-control" placeholder="Personal Email" value={emailChange} autoComplete="off"
                                                        onKeyUp={(e) => validateEmail(e)}
                                                        onChange={(e) => emailOnChange(e.target.value)} />
                                                    <label for="inputEmail">Personal Email<span style={{ color: "red", fontSize: "18px" }}>*</span></label>
                                                    <p style={{ color: 'red' }} className="error_msg">{error}</p>
                                                </div>
                                                {addmore && childrenDetails.length > 0 &&
                                                    <>
                                                        {childrenDetails.map((item, index) => (
                                                            <div className="d-flex d-flex-mb justify-content-between" id="appendedDiv">
                                                                {console.log("childrenDetailschildrenDetails", childrenDetails)}
                                                                <div className="form-label-group mb-form-label">
                                                                    <input type="text" className="form-control" placeholder="name" value={item.name} />
                                                                    <label for="inputEmail">Child Name</label>
                                                                </div>
                                                                <div className="form-label-group mb-form-label2">
                                                                    <input type="text" className="form-control" value={item.age} placeholder="name" />
                                                                    <label for="inputEmail">Age</label>
                                                                </div>
                                                                <div className="form-label-group  mb-form-label3">
                                                                    <input type="text" className="form-control" value={item.gender} placeholder="name" />
                                                                    <label for="inputEmail">Gender</label>
                                                                </div>
                                                                <div className="form-label-group " title="Delete">
                                                                    <Icon.Trash2 size={28} className="cancel" color="red" onClick={() => handleDeleteChild(index)} />
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </>
                                                }
                                                <div className="d-flex d-flex-mb justify-content-between">
                                                    <div className="form-label-group mb-form-label" >
                                                        <input type="text" className="form-control" id="childName" autoComplete="off" placeholder="name" value={childName} onChange={(e) => setChildName(e.target.value)} />
                                                        <label for="inputEmail">Child Name</label>
                                                    </div>
                                                    <div className="form-label-group mb-form-label2">
                                                        <select
                                                            name="age"
                                                            className="form-control"
                                                            value={childAge}
                                                            onChange={(e) => setChildAge(e.target.value)}
                                                        >
                                                            <option value="">Age</option>
                                                            <option value="0">{"0 = New Born"}</option>
                                                            {ageOptions.map((age) => (
                                                                <option key={age} value={age}>
                                                                    {age}
                                                                </option>)
                                                            )}
                                                        </select>

                                                    </div>
                                                    <div className="form-label-group mb-form-label3">
                                                        <select
                                                            name="location"
                                                            className="form-control" value={childGender} onChange={(e) => setChildGender(e.target.value)}
                                                        >
                                                            <option value="">Gender</option>
                                                            <option value="Male">Male</option>
                                                            <option value="Female">Female</option>
                                                        </select>
                                                    </div>
                                                    <div className="form-label-group" title="Add More">
                                                        <Icon.Plus size={30} className="addmore" onClick={handleAddChild} />
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 d-flex align-items-center m-auto">
                                                    <button className="btn btn-lg btn-block cancel_btn mr-2" onClick={() => registerCancel()} type="cancel">Cancel</button>
                                                    <button className="btn btn-lg btn-block login_btn mt-0" onClick={() => registerSave()} type="submit">Save</button>

                                                </div>
                                                {/* <button className="btn btn-lg btn-block login_btn" type="submit">Log In</button> */}
                                            </div>
                                        </>
                                    )}
                                </div>


                            </div>
                            <div className="offset-md-1 col-lg-6 img-offer"> <img src={RegisterImg} alt="Banner" class="img-fluid img-fullwidth Bannerdiv-contact-img" width="1200" height="400" /></div>

                        </div>
                    </div>
                </div>
                <Footer />
            </Suspense>
        </div>
    );
};

export default Register;
