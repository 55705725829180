import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { saveData } from "../../service";

export const saveDomainFilter = createAsyncThunk("/addSocialLink", async (parameter, thunkAPI) => {
    try {
        let resp = await saveData(parameter);
        return resp;
    } catch (err) {
        throw err;
    }
});

export const domainFilterDetailList = createAsyncThunk("/domainFilterDetailList", async (parameter, thunkAPI) => {
    try {
      let resp = await saveData(parameter);
      return resp;
    } catch (err) {
      throw err;
    }
  });

  export const apicallDeleteDomainFilter = createAsyncThunk("/apicallDeleteDomainFilter", async (parameter, thunkAPI) => {
    try {
      let resp = await saveData(parameter);
      return resp;
    } catch (err) {
      throw err;
    }
  });


export const domainFilterSlice = createSlice({
    name: "domainFilter",
    initialState: {
        saveDomainFilterResult: false,
        domainFilterList: [],
    },

    reducers: {

    },


    extraReducers: (builder) => {
        builder.addCase(saveDomainFilter.fulfilled, (state, action) => {
            state.loading = false;
            if (action.payload.success == 1) {
                state.saveDomainFilterResult = true
            }
        });
        builder.addCase(saveDomainFilter.pending, (state) => {
            state.saveDomainFilterResult = false
            state.loading = true;
        });
        builder.addCase(saveDomainFilter.rejected, (state) => {
            state.saveDomainFilterResult = false
            state.loading = false;
        });


        builder.addCase(domainFilterDetailList.fulfilled, (state, action) => {
            //   state.domainFilterList = action.payload.result.domainFilterData;
            if (action.payload.success == 1) {
              state.saveDomainFilterResult = true
              state.domainFilterList = action.payload.result.domainFilterData;
            }
            state.loading = false;
          });
          builder.addCase(domainFilterDetailList.pending, (state) => {
            state.loading = true;
            state.saveDomainFilterResult = false
          });
          builder.addCase(domainFilterDetailList.rejected, (state) => {
            state.domainFilterList = [];
            state.loading = false;
            state.saveDomainFilterResult = false
          });
    }

});

export default domainFilterSlice.reducer;

