import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { saveData } from "../../service"; 
export const loginView = createAsyncThunk("/loginData", async (parameter, thunkAPI) => {
  try {
    let resp = await saveData(parameter);
    return resp;
  } catch (err) {
    throw err;
  }
}); 
export const registerMail = createAsyncThunk("/registerMail", async (parameter, thunkAPI) => {
  try {
    let resp = await saveData(parameter);
    return resp;
  } catch (err) {
    throw err;
  }
}); 
export const loginSlice = createSlice({
  name: "log",
  initialState: {
    loginViewArray: [],
    registerArray:[]
  }, 
  reducers: { 
  },
  extraReducers: (builder) => { 
    builder.addCase(loginView.fulfilled, (state, action) => {
      console.log("....@@@@@..............",action.payload);
      state.loginViewArray = action.payload.result;
      console.log("loginViewArray",action.payload.result);
      state.loading = false;
    });
    builder.addCase(loginView.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loginView.rejected, (state) => {
      state.loginViewArray = [];
      state.loading = false;
    }); 
    builder.addCase(registerMail.fulfilled, (state, action) => {
      console.log("....@@@@@..............",action.payload);
      state.registerArray = action.payload.result;
      console.log("registerArray",action.payload.result);
      state.loading = false;
    });
    builder.addCase(registerMail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(registerMail.rejected, (state) => {
      state.registerArray = [];
      state.loading = false;
    }); 
  }
}); 
export default loginSlice.reducer;
