import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { saveData } from "../../service";
import { printParams } from "../../config";
export const homePageListing = createAsyncThunk("/homePageListing", async (parameter,thunkAPI) => {
    try {
      let resp = await saveData(parameter);
      return resp;
    } catch (err) {
      throw err;
    }
  });
  export const homePageSlice = createSlice({
    name: "homePage",
    initialState: {
     homePageDataListing:[],
    //  filteredHomePageDataBanner:[]
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(homePageListing.fulfilled, (state, action) => {
            state.homePageDataListing = action.payload.result;
            printParams("listing", action.payload.result)
                // state.memberListCount=action.payload.totalCount
                state.loading = false;
            });
            builder.addCase(homePageListing.pending, (state) => {
              state.loading = true;
            });
            builder.addCase(homePageListing.rejected, (state) => {
              state.loading = false;
            });}})
            export default homePageSlice.reducer;