import React, { Suspense, useEffect, useState } from "react";
import "./style.css"
import { registerMail } from "../redux/reducers/loginSlice";
import { masterDataListing, signUpregistration } from '../redux/reducers/masterSlice';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
let Header = React.lazy(() => import("../" + localStorage.getItem("themeName") + "/header"));
let Footer = React.lazy(() => import("../" + localStorage.getItem("themeName") + "/footer"));


const Survey = ({ }) => {
    const dispatch = useDispatch();
    const [errorMessage, seterrorMessage] = useState("")
    const [colorchange, setColorchange] = useState("red")
    const [showBtn, setshowBtn] = useState(true)

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        telephoneNumber: '',
        personalEmail: ''
    });


    useEffect(() => {
        listing()
    }, [])
    const listing = async () => {
        try {
            const result = await dispatch(
                masterDataListing({
                    "functionName": "getSiteDetails",
                    "siteId": "1",
                    "siteUrl": ""
                })
            );
            console.log("kkkkkkkkk111111111111111111", result.payload.result.siteData)
            if (localStorage.getItem("themeName") === null || localStorage.getItem("themeName") === "null" || localStorage.getItem("themeName") === "") {
                localStorage.setItem("themeName", result.payload.result.siteData[0].themeName)
                window.localStorage.setItem("siteId","1")
                window.location.reload()
            }        
        } catch (error) {
        console.error("Error occurred:", error);
      }
    }



    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = () => {
        setshowBtn(false)
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!formData.firstName) {
            //toast.error("Please Enter First Name.", { containerId: "M" })
            seterrorMessage("Please Enter First Name.")
            setColorchange("red");
            document.getElementById("firstName").focus();
            clearMessage(3000);
            setshowBtn(true)
            return;
        }
        if (!formData.lastName) {
            // toast.error("Please Enter Last Name.", { containerId: "M" })
            seterrorMessage("Please Enter Last Name.")
            setColorchange("red");
            document.getElementById("lastName").focus();
            clearMessage(3000);
            setshowBtn(true)
            return;
        }
        if (!formData.telephoneNumber) {
            // toast.error("Please Enter Telephone Number.", { containerId: "M" })
            seterrorMessage("Please Enter Telephone Number.")
            setColorchange("red");
            document.getElementById("telephoneNumber").focus();
            clearMessage(3000);
            setshowBtn(true)
            return;
        }
        if (!formData.personalEmail) {
            // toast.error("Please Enter Personal Email.", { containerId: "M" })
            seterrorMessage("Please Enter Personal Email.")
            setColorchange("red");
            document.getElementById("personalemail").focus();
            clearMessage(3000);
            setshowBtn(true)
            return;
        }
        if (!emailRegex.test(formData.personalEmail)) {
            //toast.error("Invalid email format.", { containerId: "M" })
            seterrorMessage("Invalid email format.")
            setColorchange("red");
            document.getElementById("personalemail").focus();
            clearMessage(3000);
            setshowBtn(true)
            return;
        }
        else {
            const postData = {
                "functionName": "saveOrUpdateOfferData",
                "offerDataId": "",
                "moduleTypeId": "3",
                "siteId": window.localStorage.getItem("siteId"),
                "createdOn": new Date().getTime(),
                "offerDataValue": formData
            };
            console.log("postData", postData);
            dispatch(registerMail(postData)).then((data) => {
                console.log("postData", postData);
                if (data.payload.success == "1") {
                    seterrorMessage("Thank you!! We will keep your information safe.")
                    setColorchange("green")
                    clearMessage(5000);
                    setshowBtn(true)
                    //toast.success("Saved Successfully", { containerId: "M" })
                    setFormData({
                        firstName: '',
                        lastName: '',
                        telephoneNumber: '',
                        personalEmail: ''
                    });
                }

            })
        }
    };
    const clearMessage = (time) => {
        setTimeout(() => {
            seterrorMessage("")
        }, time);
    }
    return (
        <div className="register-main-wrapper">
            <ToastContainer
                enableMultiContainer
                position="bottom-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                containerId={"M"}
                theme="colored" />
            <Suspense fallback={<div></div>}>
                <div className="container-fluid outer-padding registration-wrapper header-position">
                    <Header />
                    <div className="container" id="surveyDiv">
                        <h3 className="login_head mb-3 mt-5 text-center" style={{ color: " #eb2229" }}> Bargaining & Member Updates</h3>
                        <div className="survey-fromOuter">
                            <div className="memberUpdatesSurvey-wrpr" style={{ padding: "30px 300px" }}>
                                <label id="errorLbl" style={{ color: (colorchange), fontSize: "16px", marginBottom: "15px" }} className="d-flex justify-content-center">{errorMessage}</label>
                                <div className="form-label-group">
                                    <input type="text" id="firstName" name="firstName" className="form-control" placeholder="First Name" value={formData.firstName} onChange={handleChange} />
                                    <label for="firstName">First Name</label>
                                </div>
                                <div className="form-label-group">
                                    <input type="text" id="lastName" name="lastName" className="form-control" placeholder="Last Name" value={formData.lastName} onChange={handleChange} />
                                    <label for="lastName">Last Name</label>
                                </div>
                                <div className="form-label-group">
                                    <input type="text" id="telephoneNumber" name="telephoneNumber" className="form-control" placeholder="Telephone Number" value={formData.telephoneNumber} onChange={handleChange} />
                                    <label for="telephoneNumber">Telephone Number</label>
                                </div>
                                <div className="form-label-group">
                                    <input type="email" id="personalemail" name="personalEmail" className="form-control" placeholder="Personal email" value={formData.personalEmail} onChange={handleChange} />
                                    <label htmlFor="personalemail">Personal email</label>
                                </div>
                                <div className="d-flex" style={{ justifyContent: "right" }}>
                                    <span className="btn btn-lg btn-block login_btn mt-0" style={{ width: "150px", display: (showBtn === true ? "block" : "none") }} type="submit" onClick={() => handleSubmit()}>Save</span>
                                    <span className="btn btn-lg btn-block login_btn mt-0" style={{ width: "150px", display: !showBtn === true ? "block" : "none" }} type="submit" >Please wait</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </Suspense>
        </div>
    );
};

export default Survey;
