import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { saveData } from "../../service";
import { act } from "react-dom/test-utils";

export const getSocialLink = createAsyncThunk("/getSocialLink", async (parameter, thunkAPI) => {
    try {
        let resp = await saveData(parameter);
        return resp;
    } catch (err) {
        throw err;
    }
});



export const socialLinksSlice = createSlice({
    name: "socialLinks",
    initialState: {
        getSocialLinkList: [],
    },

    reducers: {

    },




    extraReducers: (builder) => {
        builder.addCase(getSocialLink.fulfilled, (state, action) => {
            state.loading = false;
            if (action.payload.success == 1) {
                state.getSocialLinkList = action.payload.result.socialMediaData

            }
        });
        builder.addCase(getSocialLink.pending, (state) => {
            state.getSocialLinkList = []
            state.loading = true;
        });
        builder.addCase(getSocialLink.rejected, (state) => {
            state.getSocialLinkList = []
            state.loading = false;
        });
    }
});
export default socialLinksSlice.reducer;