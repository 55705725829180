import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { saveData } from "../../service";
import { printParams } from "../../config";
export const masterDataListing = createAsyncThunk("/masterDataListing", async (parameter, thunkAPI) => {
  try {
    let resp = await saveData(parameter);
    return resp;
  } catch (err) {
    throw err;
  }
});
export const activePopupListing = createAsyncThunk("/activePopupListing", async (parameter, thunkAPI) => {
  try {
    let resp = await saveData(parameter);
    return resp;
  } catch (err) {
    throw err;
  }
});
export const signUpregistration = createAsyncThunk("/signUpregistration", async (parameter, thunkAPI) => {
  try {
    let resp = await saveData(parameter);
    return resp;
  } catch (err) {
    throw err;
  }
});
export const masterSlice = createSlice({
  name: "masterPage",
  initialState: {
    masterPageDataListing: [],
    masterPageFlag:false,
    activePopupListingData:[],
    signUpMemberResult:false
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(masterDataListing.fulfilled, (state, action) => {
      state.masterPageDataListing = action.payload.result.siteData;
      printParams("masterlisting", action.payload.result.siteData)
      // state.memberListCount=action.payload.totalCount
      state.masterPageFlag = true
      state.loading = false;
    });
    builder.addCase(masterDataListing.pending, (state) => {
      state.loading = true;
      state.masterPageFlag = false
    });
    builder.addCase(masterDataListing.rejected, (state) => {
      state.masterPageDataListing = [];
      state.masterPageFlag = false
      state.loading = false;
    });
    builder.addCase(activePopupListing.fulfilled, (state, action) => {
      state.activePopupListingData = action.payload.result;
      printParams("activePopupListingData", action.payload.result)
      // state.memberListCount=action.payload.totalCount
      // state.masterPageFlag = true
      state.loading = false;
    });
    builder.addCase(activePopupListing.pending, (state) => {
      state.loading = true;
      // state.masterPageFlag = false
    });
    builder.addCase(activePopupListing.rejected, (state) => {
      state.activePopupListingData = [];
      // state.masterPageFlag = false
      state.loading = false;
    });

    builder.addCase(signUpregistration.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.success == 1) {
        state.signUpMemberResult = true
      }
    });
    builder.addCase(signUpregistration.pending, (state) => {
      state.signUpMemberResult = false
      state.loading = true;
    });
    builder.addCase(signUpregistration.rejected, (state) => {
      state.signUpMemberResult = false
      state.loading = false;
    });
    
  }
})
export default masterSlice.reducer;