import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";


 import EqualityStatment from "./EqualityStatement/equalityStatement";
 import Contact from "./Contact/contact";
 import News from "./Common/news";
 import Register from "./Common/register";
 import Survey from "./Common/survey";
 import BargainingMemberUpdates from "./Common/member-updates-survey";
 import MemberLogin from "./Common/member-login"
// import EmailBlast from "./admin/email-blast";
// import TextBlast from "./admin/text-blast";
// import Login from "./admin/login";
// import Services from "./admin/services";
// import MemberManagement from "./admin/member-management";
// import AddMember from "./admin/add-member";
import RootFile from "./Main/rootFile";
import { Provider } from "react-redux";
import store from "./redux/store";
/********** Theme 1 **********/
//  import Theme1Home from "./BunchBerry/home";
// import Theme2Home from "./theme2/home";
// import Theme2News from './theme2/news'
// import NewsDetails from "./Common/news-details";
// import NewsDetailsTheme3 from './theme2/news-details'

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(
	<Provider store={store}>
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<RootFile />} />
				 <Route path="/equalityStatment" element={<EqualityStatment />} />
				 <Route path="/contact" element={<Contact />} />
				 <Route path="/news" element={<News />} /> 
				 <Route path="/register" element={<Register />} /> 
				 <Route path="/survey" element={<Survey />} /> 
				 <Route path="/bargaining-and-member-updates" element={<BargainingMemberUpdates />} /> 
				 <Route path="/admin" element={<MemberLogin />} /> 
				{/* <Route path="/email-blast" element={<EmailBlast />} />
				<Route path="/text-blast" element={<TextBlast />} />
				<Route path="/login" element={<Login />} />
				<Route path="/services" element={<Services />} />
				<Route path="/member-management" element={<MemberManagement />} />
				<Route path="/add-member" element={<AddMember />} />  */}
				{/* Theme 1 */}
				{/* <Route path="/home-theme1" element={<Theme1Home />} /> * */}
				{/* <Route path="/home-theme2" element={<Theme2Home />} />
				<Route path="/news" element={<Theme2News />} />NewsDetails
				<Route path="/news-details" element={<NewsDetailsTheme3 />} />  */}
				{/* <Route path="/news-details" element={<NewsDetails />} />   */}

			</Routes>
		</BrowserRouter>
	</Provider>
);
// render(
//   <BrowserRouter>
//     <Routes>
//       <Route path="/" element={<FileUpload />} />
//       <Route path="/ocr_reprocessing" element={<Reprocessing />} />
//     </Routes>
//   </BrowserRouter>,
//   rootElement
// );
