import React, { useState } from "react";
import { useEffect } from "react";

import Fabio from "../images/default-img.png";
// import Ryan from "../images/ryan.png";
// import Chris from "../images/chris.png";
// import Mohit from "../images/mohit.png";
// import Shona from "../images/shona.png";
import { FaDownload } from "react-icons/fa";
import Mail from "../images/mail.png";
import $ from "jquery";
import { useDispatch, useSelector } from 'react-redux';
import { executiveListing } from '../redux/reducers/executiveSlice';
import { Link } from "react-router-dom";
function Executive() {
	const dispatch = useDispatch();
	const { executiveList, executiveListCount } = useSelector((state) => state.executive);
	const [activePage, setActivePage] = useState();


	useEffect(() => {
		dispatch(executiveListing({
			"functionName": "getExecutivesList",
			"offset": "0",
			"max": "10"
		}));
		$("#contact").addClass("active").css("color", "#000");
	}, [dispatch]);

	const handleDownload = () => {
		const pdfUrl = 'https://cupefiles-prod.s3.ca-central-1.amazonaws.com/production/documents/CUPE_831_Board_2024.pdf';
	
		fetch(pdfUrl)
		  .then(response => response.blob())
		  .then(blob => {
			const url = window.URL.createObjectURL(new Blob([blob]));
			const a = document.createElement('a');
			a.href = url;
			a.download = 'CUPE_831_Board_2024.pdf';
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
			window.URL.revokeObjectURL(url);
		  })
		  .catch(error => {
			console.error('Error downloading PDF:', error);
		  });
	  };
	return (
		<div className="executive_div">
			<div className="container Executivediv">
				<div className="main-head-topSec">
				<h2 className="main-head mainhead-contact mb-3">Executives</h2>
				<button className="excecutive-borderBtn" onClick={handleDownload}>Executive Board Download <FaDownload className="ml-2"/></button>
				</div>
				<div className="ExecutiveOuter">
					{executiveList.map((executiveListData, index) => (
						<div className="executiveBox-inline">
							<div className="executiveBox">
								{/* <div className="executiveBox-left">
									{executiveListData?.image_name ? (
										<img src={process.env.REACT_APP_S3_BUCKET_URL+process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE + process.env.REACT_APP_CUPETHEME_PATH + executiveListData?.image_name} alt="Fabio" className="img-fluid executiveBox-Img" width="100" height="100" />
									) :
										(<img src={Fabio} alt="Fabio" className="img-fluid executiveBox-Img" width="100" height="100" />
										)}

								</div> */}
								<div className="executiveBox-right">
									<div className="executiveBox-rightTop">
										<h3 className="executiveBox-subhead">{executiveListData.extension}</h3>
										<p className="executiveBox-para">{executiveListData.unionPositionName}</p>
									</div>
									<div className="executiveBox-rightBottom">
										<img src={Mail} alt="Email" className="img-fluid" width="100" height="100" />
										<Link to={"mailto:" + executiveListData.userName} className="executiveBox-mailText">{executiveListData.userName}</Link>
									</div>
								</div>
							</div>
						</div>
					))}

				</div>
			</div>
		</div>
	);

}
export default Executive;
