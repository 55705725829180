import React, { useEffect,useState } from "react";
import EqualityBottom from "../images/equality-bottom.png"

import { printParams } from "../config";
const BottomSection= (aboutData) => {
    const [aboutImage, setAboutImage] = useState("");
    var getS3folder = process.env.REACT_APP_S3_BUCKET_URL+process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE + process.env.REACT_APP_CUPETHEME_PATH
    useEffect(() => {
         printParams("aboutData", aboutData);
         const firstThemeData = aboutData.aboutData[0];
         printParams("aboutData11111111111111111", firstThemeData);
        if (firstThemeData) {
            try {
                const themeData = JSON.parse(firstThemeData.themeData);
                printParams("themeData", themeData);
                // setAboutHeading(themeData[0].aboutHeading)
                // setAboutContentSubHead(themeData[0].aboutSubHeading)
                // setAboutContentPara(themeData[0].aboutContentParagraph)
                setAboutImage(themeData[0].aboutImage)
            } catch (error) {
                printParams("Error parsing themeData:", error);
            }
        } else {
            printParams("aboutData or themeData is undefined.");
        }
    }, [aboutData]);
        return (
            <div className="container bottomsection-wrapper">
                <div className="col-lg-12 col-md-12 bottom-left text-center">
                    <div className="bottom-left-img">
                        <img src={getS3folder+"/"+aboutImage} alt="Equality Statement" className="img-fluid equality-btm-imgg"  width="600" height="400"/>
                    </div>
                    {/* <div className="col-lg-6 col-md-12 bottom-right">
                        <p className="bottom-para">CUPE’s policies and practices must reflect our commitment to equality. Members, staff and elected officers must be mindful that all sisters and brothers deserve dignity, equality and respect.</p>
                        <p className="bottom-para">Sometimes discrimination takes the form of harassment. Harassment means using real or perceived power to abuse, devalue or humiliate. Harassment s​hould not be treated as a joke. The uneasiness and resentment that it creates are not feelings that help us grow as a union.</p>
                        <p className="bottom-para">Discrimination and harassment focus on characteristics that make us different; and they reduce our capacity to work together on shared concerns such as decent wages, safe working conditions, and justice in the workplace, society and in our union.</p>
                    </div> */}
                </div>
            </div>
        );
    }
export default BottomSection;
