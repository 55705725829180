import React, { useEffect } from 'react';
import RootFileSub from './rootFileSub';
import { printParams } from "../config";
import { masterDataListing , activePopupListing} from '../redux/reducers/masterSlice';
import { useDispatch, useSelector } from 'react-redux';

const RootFile = () => {
  const dispatch = useDispatch();
  const { masterPageDataListing=[{themeName:""}]} = useSelector((state) => state.masterPage);

  useEffect(() => {
    listing();
    // activePopupList();
  }, []);

  
  const listing = async () => {
    try {
      const result = await dispatch(
        masterDataListing({
          "functionName": "getSiteDetails",
          "siteId": "1",
          "siteUrl": ""
        })
      );
      console.log("kkkkkkkkk",result.payload.result.siteData[0].themeName)
        localStorage.setItem("themeName",result.payload.result.siteData[0].themeName)
       localStorage.setItem("siteId",result.payload.result.siteData[0].id)
    //  localStorage.setItem("themeName","BlueBerry")
      
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  return (
    <div>
      <RootFileSub />
    </div>
  );
};

export default RootFile;
