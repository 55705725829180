import React, { useState, useEffect, Suspense } from "react";
import * as Icon from "react-feather";
import News from "../images/news-img.png";
import Logo from "../images/logo.png";
// import Header from "../MapleLeaf/header";
import { Link } from "react-router-dom";
import { newsListing } from '../redux/reducers/newSlice';
import { useDispatch, useSelector } from 'react-redux';
import { masterDataListing } from '../redux/reducers/masterSlice';
import $ from "jquery";
import { FaPrint } from 'react-icons/fa';
let Header = React.lazy(() => import("../" + localStorage.getItem("themeName") + "/header"));
let Footer = React.lazy(() => import("../" + localStorage.getItem("themeName") + "/footer"));
// import Banner from "./contactBanner";
function NewsHome() {
    const dispatch = useDispatch()
    const { newsListData } = useSelector((state) => state.newsList);
    const { masterPageDataListing, masterPageFlag } = useSelector((state) => state.masterPage);
    const [date] = useState(new Date().getTime())
    const [logo, setLogo] = useState([]);

    useEffect(() => {
        console.log("masterPageDataListing111111", masterPageDataListing)
        setLogo(masterPageDataListing)
        listing()
        dispatch(newsListing({
            "functionName": "getNewsDetails",
            "siteId": window.localStorage.getItem("siteId"),
            "newsId": "",
            "max": "10",
            "offset": "0"
        }));

    }, [dispatch])
    $("#News").addClass("active").css("color", "#000");
    const listing = async () => {
        try {
            const result = await dispatch(
                masterDataListing({
                    "functionName": "getSiteDetails",
                    "siteId": window.localStorage.getItem("siteId"),
                    "siteUrl": ""
                })
            );
            console.log("kkkkkkkkk111111111111111111", result.payload.result.siteData)
            //    localStorage.setItem("themeName",result.payload.result.siteData[0].themeName)
            // localStorage.setItem("themeName","GrayJay")
            //   if(masterPageDataListing.length>0){
            setLogo(result.payload.result.siteData)
            //   }

        } catch (error) {
            console.error("Error occurred:", error);
        }
    };
    function calculateDaysAgo(createdTimestamp) {
        // debugger
        const createdTimestampValue = parseInt(createdTimestamp)
        const currentTimestamp = Date.now();
        const differenceInMilliseconds = currentTimestamp - createdTimestampValue;
        const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));

        if (differenceInDays === 0) {
            return 'Today';
        } else if (differenceInDays === 1) {
            return 'Yesterday';
        } else {
            return `${differenceInDays} days ago`;
        }
    }

    const handleDownload = (data) => {
        const imageUrl = process.env.REACT_APP_S3_BUCKET_URL + process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE + process.env.REACT_APP_CUPETHEME_PATH + "/" + data?.image_name;

        // const img = new Image();
        // img.src = imageUrl;

        // img.onload = () => {
        //     const printWindow = window.open('_blank');
        //     //  printWindow.document.open();
        //     printWindow.document.write('<img src="' + imageUrl + '" onload="window.print();window.close();" />');
        //     //  printWindow.document.close();
        // };
        var printWindow = window.open("", '_blank');
        printWindow.document.write('<html>');
        printWindow.document.write('<body > ');
        printWindow.document.write('<img src="' + imageUrl + '" style="max-width: 100%;" />');
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print();
        printWindow.close();

    };



    return (
        <div className="footer_white">
            <Suspense fallback={<div></div>}>
                <div className="container-fluid outer-padding news-wrapper header-position">
                    <Header />
                    {/* <Banner /> */}
                    <div className="card-columns mt-5 container card_columns_div">
                        {console.log("........................................lll", newsListData)}
                        {newsListData.map((data, index) => (
                            <div className="card border flex-md-row mb-4 h-md-250 position-relative card-div">
                                <div className="col d-flex flex-column card-div-sub">
                                    <div class="d-flex justify-content-between align-items-center py-2 position-relative">
                                        {data.mode === "Site" && (
                                            <>
                                                {logo.map((obj) => (
                                                    <div className="logo-news">
                                                        <img src={process.env.REACT_APP_S3_BUCKET_URL + process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE + process.env.REACT_APP_CUPETHEME_PATH + "/" + obj.logo} alt={""} style={{ width: "60px", height: "40px" }} className="img-fluid" />
                                                    </div>))}
                                            </>
                                        )}

                                        <div className="d-flex justify-content-between align-items-center">
                                            {(data?.description == "" &&  data.image_name !== "") && (
                                                <span className="news-printdwnlodBtn mr-2" style={{color:"#6c6666", cursor:"pointer", fontSize:"16px"}} onClick={() => handleDownload(data)}> <FaPrint className="ml-2" /></span>)}
                                            <small class="text-muted ml-auto mt-2"> {calculateDaysAgo(data.created_on)}</small>
                                        </div>
                                    </div>
                                    {data?.image_name && data?.image_name.endsWith('.pdf') ? (
                                        <embed src={process.env.REACT_APP_S3_BUCKET_URL + process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE + process.env.REACT_APP_CUPETHEME_PATH + "/" + data?.image_name} width="100%" height="455px" type="application/pdf" style={{ backgroundColor: "#fff" }} />
                                    ) : (
                                        data.image_name !== "" && (
                                            <div className="">
                                                <img src={process.env.REACT_APP_S3_BUCKET_URL + process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE + process.env.REACT_APP_CUPETHEME_PATH + "/" + data?.image_name} alt="UserImg" className="img-fluid news-list-img" width="1440" height="200" />

                                            </div>))}
                                    {data?.description !== "" && (
                                        <div className="news-content">
                                            <p><div dangerouslySetInnerHTML={{ __html: data?.description }} /></p>
                                            {/* <Link className="btn btn-block  news_btn" to="/news-details">
                   CLICK HERE
               </Link> */}

                                        </div>)}
                                </div>
                            </div>))}

                    </div>

                </div>
                <Footer />
            </Suspense>
        </div>
    );
}

export default NewsHome;
