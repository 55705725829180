import React,{Suspense} from "react";
// import Header from "../MapleLeaf/header";
import Banner from "./contactBanner";
import Executive from "./executives";
// import Footer from "../MapleLeaf/footer";
let Header = React.lazy(() => import("../" + localStorage.getItem("themeName") + "/header"));
let Footer = React.lazy(() => import("../" + localStorage.getItem("themeName") + "/footer"));
const Contact = () => {
  return (
    <div className="footer_white">
       <Suspense fallback={<div></div>}>
      <div className="container-fluid outer-padding contact-wrapper  header-position">
        <Header />
        <Banner />
        <Executive />
      </div>
      <Footer />
      </Suspense>
    </div>
  );
};

export default Contact;
