import React, { useState, useEffect, Suspense } from 'react';
import cupeLogo from "../images/cupe.png"
import BannerPopupImg from "../images/CUPE831_ApplicationForMembership-300x214.jpg";
import { masterDataListing, activePopupListing } from '../redux/reducers/masterSlice';
import { useDispatch, useSelector } from 'react-redux';

const RootFileSub = () => {
  const dispatch = useDispatch();
  const { activePopupListingData = { popUpMessageData: [] } } = useSelector((state) => state.masterPage);
  const [css, setCss] = useState(null);
  const [isPopupVisible, setPopupVisible] = useState(true);
  const [popUpData, setPopUpData] = useState([]);
  const [logo, setLogo] = useState([]);

  useEffect(() => {
    const loadTheme = async () => {
      try {
        const result = await dispatch(
          masterDataListing({
            "functionName": "getSiteDetails",
            "siteId": "1",
            "siteUrl": ""
          })
        );
         const themeName = result.payload.result.siteData[0].themeName;
        // const themeName = "BlueBerry";
        localStorage.setItem("themeName", themeName);
        setCss(themeName);
        console.log("theme", themeName);
        console.log("xxxxxx", localStorage.getItem("themeName"));
        setLogo(result.payload.result.siteData)
        activePopupList();
      } catch (error) {
        console.error("Error occurred:", error);
      }
    };

    loadTheme();
  }, []);

  const activePopupList = async () => {
    try {
      const result = await dispatch(
        activePopupListing({
          "functionName": "getActivePopUpMessageDetails",
          "siteId": "1",
          "isPublic": true
        })
      );
      console.log("kkkkkkkkk111111111111", result.payload);
      if (result) {
        setPopUpData(result.payload.result.popUpMessageData);
      }

    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const handleCloseClick = () => {
    // Toggle the visibility of the popup when the close button is clicked
    setPopupVisible(false);
  };

  if (css === null) {
    return null; // Render nothing if css is null
  }

  const Home = React.lazy(() => import(`../${css}/home`));
  const Header = React.lazy(() => import(`../${css}/header`));
  const Footer = React.lazy(() => import(`../${css}/footer`));

  return (
    <div>
      <Suspense fallback={<div></div>}>
        <section className={css}>
          <Header />
          <Home />
          <Footer />
        </section>
      </Suspense>
      {console.log("popUpData", popUpData)}
      {(isPopupVisible && popUpData.length > 0) && (
        <div>
          <div className="modal fade show" id="homePopup" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true"
            style={{ display: "block" }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              {popUpData.map((item, index) => (
                <div className="modal-content" key={index}>
                  <div className="modal-header">
                  
                    <h5 className="modal-title text-center" style={{color:"#000"}}>
                    {logo?.map((obj) => (
                      <img src={process.env.REACT_APP_S3_BUCKET_URL+process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE + process.env.REACT_APP_CUPETHEME_PATH +"/"+ obj.logo} width={39} height={39} className='mr-2' alt="Cupe Logo"></img>))}
                      
                      <b className="modal-title-text">{item.title}</b>
                    </h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleCloseClick}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <a href={item.link_text} className="link_text">
                  <div className="modal-body mb-2 mb-md-5">
                    <div className="row">
                      <div className='col-md-12 text-center mb-3'>
                        <a href={item.link_text} className='apply-linktext' style={{ fontSize: "16px", color:"#e60000",fontWeight:"600" }}>{item.link}</a>
                      </div>
                      <div className='col-md-12 text-center'>
                        <img src={process.env.REACT_APP_S3_BUCKET_URL+process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE + process.env.REACT_APP_CUPETHEME_PATH +"/"+ item.file_name} className='img-fluid popup-common-image' alt="Banner" />
                      </div>
                    </div>
                  </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
          <div className="modal-backdrop fade show"></div>
        </div>
      )}
    </div>
  );
};

export default RootFileSub;
