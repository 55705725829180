import { configureStore, combineReducers } from "@reduxjs/toolkit";
import counterSlice from "./reducers/counterSlice";
import homePageSlice from "./reducers/homePageSlice"
import executiveSlice from "./reducers/executiveSlice";
import newSlice from "./reducers/newSlice";
import loginSlice from "./reducers/loginSlice";
import masterSlice from "./reducers/masterSlice";
import socialLinksSlice from  './reducers/socialLinksSlice'
import domainFilterSlice from './reducers/domainFilterSlice'
// import memberSlice from "./reducers/memberSlice";
// import stylingSlice from "./reducers/stylingSlice";
// import projectsSlice from "./reducers/memberSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
const persistConfig = {
  key: "root",
  version: 1,
  storage,
};
const reducer = combineReducers({
  counter: counterSlice,
  homePage: homePageSlice,
  executive: executiveSlice,
  newsList:newSlice,
  masterPage:masterSlice,
  log: loginSlice,
  socialLinks : socialLinksSlice,
  domainFilter : domainFilterSlice,
});
const persistedReducer = persistReducer(persistConfig, reducer);
export default configureStore({
  reducer: persistedReducer,
});
// export default configureStore({
//  reducer: {
//  },
// });